import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
import PropTypes from "prop-types"
import Transition from "../components/transition"
import Menu from "../components/menu/menu"
import RenderInBrowser from "react-render-in-browser"
import McFly from "../images/mcfly.jpg"

const Layout = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    {
      allSanityMainMenu {
        edges {
          node {
            mainMenu {
              ... on SanityCustomLink {
                title
                url {
                  current
                }
              }
              ... on SanityPage {
                id
                title
                slug {
                  current
                }
              }
            }
          }
        }
      }
      allSanityService {
        edges {
          node {
            allServices {
              title
              slug {
                current
              }
            }
          }
        }
      }
      allSanityStudioPost {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <RenderInBrowser ie only>
        <div
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9999999;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              left: 0;
              top: 0;
            }

            p {
              color: #fefefe;
              font-family: "Montserrat", sans-serif;
              font-weight: 700;
              font-size: 3rem;
              z-index: 2;
              max-width: 80%;
              margin: 0 auto;
            }
          `}
        >
          <img src={McFly} alt="Marty McFly" />
          <p>is that you Marty Mcfly?</p>
          <p>
            time travel is not supported on this website. please upgrade to a
            modern browser to view this site.
          </p>
        </div>
      </RenderInBrowser>
      <Menu menuItems={data} />
      <Transition location={location}>{children}</Transition>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
