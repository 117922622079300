require("./src/css/reset.css")

export const onRouteUpdate = ({ location }) => {
  const menu = document.querySelector(".menu")
  const button = document.querySelector(".header__button")
  const drilldown = document.querySelector(".drilldown")
  const drilldown2 = document.querySelector(".drilldown2")

  if (menu) {
    if (menu.classList.contains("active")) {
      menu.classList.remove("active")
    }
  }
  if (button) {
    if (button.classList.contains("open")) {
      button.classList.remove("open")
    }
  }
  if (drilldown) {
    if (drilldown.classList.contains("open")) {
      drilldown.classList.remove("open")
    }
  }
  if (drilldown2) {
    if (drilldown2.classList.contains("open")) {
      drilldown2.classList.remove("open")
    }
  }
}
