import styled from "@emotion/styled"

export const TransparentText = styled.p`
  font-family: "Yantramanav", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fefefe;
  text-decoration: none;
  line-height: 0.85;
  font-size: 6rem;
  margin: 0;
  transition: all 0.4s ease-in-out;

  :hover {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: #fefefe;
  }
`
