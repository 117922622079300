import React, { Component } from "react"
import { Link } from "gatsby"
import { TransparentText } from "../../styled-components/transparent-text"
import {
  MenuMainMenuItem,
  MenuRight,
  MenuMainMenu,
  MenuDrilldown,
  MenuDrilldownBack,
  MenuDrilldownItem,
  MenuTopLeftWrapper,
  MenuTopRightWrapper,
  MenuBottomLeftWrapper,
  MenuBottomRightWrapper,
  MenuTopLeft,
  MenuTopRight,
  MenuBottomLeft,
  MenuBottomRight,
  MenuClose,
  MenuWrapper,
  MenuLink,
} from "./menu-styles"
import TopRightVideo from "../../videos/gray-menu-smoke-logo.mp4"
import { css } from "@emotion/core"

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = { isMobile: false }
  }

  closeMenu() {
    document.querySelector(".menu").classList.remove("active")
    document.querySelector(".header__button").classList.remove("open")

    if (document.querySelector(".drilldown").classList.contains("open")) {
      document.querySelector(".drilldown").classList.remove("open")
    }
  }

  openDrilldown() {
    document.querySelector(".drilldown").classList.add("open")
  }

  closeDrilldown() {
    document.querySelector(".drilldown").classList.remove("open")
  }

  openDrilldown2() {
    document.querySelector(".drilldown2").classList.add("open")
  }

  closeDrilldown2() {
    document.querySelector(".drilldown2").classList.remove("open")
  }

  componentDidMount() {
    if (window.matchMedia("(max-width: 1279px)").matches) {
      this.setState(() => {
        return {
          isMobile: true,
        }
      })
    }
  }

  render() {
    const mainMenu = this.props.menuItems.allSanityMainMenu.edges[1].node
    const allServices = this.props.menuItems.allSanityService.edges[0].node
    const allStudios = this.props.menuItems.allSanityStudioPost.edges

    return (
      <>
        <MenuWrapper className="menu">
          <MenuClose onClick={this.closeMenu}>&times;</MenuClose>
          <MenuRight>
            <MenuMainMenu>
              {mainMenu.mainMenu.map((item, index) => {
                if (item.title === "Services") {
                  return (
                    <MenuMainMenuItem onClick={this.openDrilldown} key={index}>
                      <TransparentText>{item.title}</TransparentText>
                    </MenuMainMenuItem>
                  )
                } else if (item.title === "Studios") {
                  return (
                    <MenuMainMenuItem onClick={this.openDrilldown2} key={index}>
                      <TransparentText>{item.title}</TransparentText>
                    </MenuMainMenuItem>
                  )
                } else {
                  return (
                    <MenuMainMenuItem key={index}>
                      <TransparentText>
                        <Link to={item.url.current}>{item.title}</Link>
                      </TransparentText>
                    </MenuMainMenuItem>
                  )
                }
              })}
            </MenuMainMenu>
            <MenuDrilldown className="drilldown">
              <MenuDrilldownBack onClick={this.closeDrilldown}>
                Back
              </MenuDrilldownBack>
              {allServices.allServices.map((item, index) => (
                <MenuDrilldownItem key={index}>
                  <MenuLink to={"/" + item.slug.current + "/"}>
                    {item.title.replace("for Law Firms", "")}
                  </MenuLink>
                </MenuDrilldownItem>
              ))}
            </MenuDrilldown>
            <MenuDrilldown
              className="drilldown2"
              css={css`
                padding-left: 0;
              `}
            >
              <MenuDrilldownBack onClick={this.closeDrilldown2}>
                Back
              </MenuDrilldownBack>
              {allStudios.map(({ node }, index) => (
                <MenuDrilldownItem
                  key={index}
                  css={css`
                    text-align: center;
                    max-width: 75%;
                    margin: 0 auto;
                  `}
                >
                  <MenuLink to={"/" + node.slug.current + "/"}>
                    {node.title}
                  </MenuLink>
                </MenuDrilldownItem>
              ))}
            </MenuDrilldown>
          </MenuRight>
          <MenuTopLeftWrapper>
            <MenuTopLeft to="/studios">
              <p>nextlevel studios</p>
              check it out
            </MenuTopLeft>
          </MenuTopLeftWrapper>
          <MenuTopRightWrapper to="/services">
            <div>
              <MenuTopRight
                src={TopRightVideo}
                autoPlay={false}
                loop={true}
                muted={true}
                playsInline
              />
            </div>
          </MenuTopRightWrapper>
          <MenuBottomLeftWrapper>
            <MenuBottomLeft href="mailto:info@nextlevel.com">
              <p>join us now</p>
              Interested in a<br />
              NextLevel Career?
            </MenuBottomLeft>
          </MenuBottomLeftWrapper>
          <MenuBottomRightWrapper>
            <MenuBottomRight>
              <p>We generate leads. You practice law.</p>
            </MenuBottomRight>
          </MenuBottomRightWrapper>
        </MenuWrapper>
      </>
    )
  }
}

export default Menu
