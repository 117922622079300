import React, { PureComponent } from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"
import { TimelineLite } from "gsap"

const duration = 1600
const anim = (duration / 1000) * 0.33

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const zI = 199

const animBorder = {
  width: "100vw",
  height: "100vh",
  boxSizing: "border-box",
  position: "absolute",
  left: 0,
  top: 0,
  backgroundColor: "transparent",
  border: "2rem solid #292929",
  zIndex: zI,
  pointerEvents: "none",
}

const animBox = {
  width: "calc(100% - 4rem)",
  boxSizing: "border-box",
  position: "absolute",
  left: "2rem",
  top: "2rem",
  backgroundColor: "#232323",
  zIndex: zI,
  pointerEvents: "none",
}

const animLine = {
  width: "2px",
  height: "50vh",
  position: "absolute",
  left: "calc(50% - 1px)",
  top: "25vh",
  backgroundColor: "#fefefe",
  zIndex: zI,
  pointerEvents: "none",
}

let tl1
let tl2
let tl3

class Transition extends PureComponent {
  transitionAnim() {
    tl1.to(".animation-border", anim, {
      opacity: "1",
    })

    if (window.matchMedia("(min-width: 1024px)").matches) {
      tl2.to(".animation-box", anim, {
        height: `${window.innerHeight - 64}px`,
      })
    } else {
      tl2.to(".animation-box", anim, {
        height: `${window.innerHeight}px`,
      })
    }

    tl3
      .to(".animation-line", anim, {
        // Growns Down
        height: "50vh",
      })
      .to(".animation-line", anim, {
        // Shrinks Down
        y: "50vh",
        height: "0px",
      })
      .set(".animation-line", {
        // Reset
        height: "0px",
        y: "0",
      })
      .to(".animation-line", anim, {
        // Grows Down
        height: "50vh",
      })
      .to(".animation-line", anim, {
        // Shrinks Down
        y: "50vh",
        height: "0px",
      })
      .set(".animation-line", {
        // Reset
        height: "0px",
        y: "0",
      })
  }

  interruptAnim() {
    tl3.progress(1)
  }

  componentDidMount() {
    tl1 = new TimelineLite()
    tl2 = new TimelineLite()
    tl3 = new TimelineLite({
      onComplete: () => {
        tl1.to(".animation-border", anim, {
          opacity: "0",
        })

        tl2.to(".animation-box", anim, {
          height: "0px",
        })
      },
    })

    const tl = new TimelineLite()
    tl.set(".animation-border", {
      opacity: "0",
    })
      .set(".animation-box", {
        height: "0px",
      })
      .set(".animation-line", {
        height: "0px",
      })
  }

  render() {
    const { children, location } = this.props
    return (
      <>
        <div className="animation-border" style={animBorder} />
        <div className="animation-box" style={animBox} />
        <div className="animation-line" style={animLine} />
        <TransitionGroup component={null}>
          <ReactTransition
            key={location.pathname}
            timeout={duration}
            onEntering={this.transitionAnim}
            onExited={this.interruptAnim}
          >
            {state => (
              <main
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
              >
                {children}
              </main>
            )}
          </ReactTransition>
        </TransitionGroup>
      </>
    )
  }
}
export default Transition
